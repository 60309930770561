let yearFrom = 2013;
const currentYear = new Date().getFullYear();
let yearTo = currentYear;
const years = [];

while (yearTo >= yearFrom) {
  years.push(yearTo);
  yearTo += -1;
}

const allClassLevels = [13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
const majorClassLevels = [13, 12, 11];

const allClasses = [
  { title: "All Levels", level: allClassLevels },
  { title: "CCI5*-L", level: 13 },
  { title: "CCI4*-L", level: 12 },
  { title: "CCI4*-S", level: 11 },
  { title: "Advanced", level: 10 },
  { title: "CCI3*-L", level: 9 },
  { title: "CCI3*-S", level: 8 },
  { title: "Intermediate", level: 7 },
  { title: "CCI2*-L", level: 6 },
  { title: "CCI2*-S", level: 5 },
  { title: "Novice", level: 4 },
  { title: "CCI1*-U & BE105", level: 3 },
  { title: "BE100", level: 2 },
  { title: "BE80 and BE90", level: 1 },
];

const majorClassesFilter = (c) => { return majorClassLevels.includes(c.level) };

const powerAverageRegionTitles = {
  "scotland": "Scotland",
  "north": "North",
  "wales_and_west_midlands": "Wales & West Midlands",
  "east_midlands": "East Midlands",
  "central": "Central",
  "east": "East",
  "south_west": "South West",
  "south_east": "South East",
}

// ========================================================================================
// WARNING:
//  classLevelParam must always have a height_levels[] param
//  If this is missing and er_levels[] is used instead, it will return nothing
//  Using er_levels[] can only be achieved by adding height_levels[]=1-8
// ========================================================================================
const lastDayOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
const dateToBE80BE90 = new Date('2025-07-31');

let dateToParamBE80BE90 = lastDayOfLastMonth < dateToBE80BE90 ? lastDayOfLastMonth : dateToBE80BE90;
dateToParamBE80BE90 = dateToParamBE80BE90.toISOString().split('T')[0];

const powerAverageClassLevelParams = {
  "be80": {
    "classLevelTitle": "BE80",
    "classLevelParam": "&height_levels[]=1",
    "under18Param": "&under_18=false",
    "dateFromParam": "&date_from=2024-07-01",
    "dateToParam": `&date_to=${dateToParamBE80BE90}`
  },
  "be90": {
    "classLevelTitle": "BE90",
    "classLevelParam": "&height_levels[]=2",
    "under18Param": "&under_18=false",
    "dateFromParam": "&date_from=2024-07-01",
    "dateToParam": `&date_to=${dateToParamBE80BE90}`
  },
  "be100": {
    "classLevelTitle": "BE100",
    "classLevelParam": "&height_levels[]=3&height_levels[]=4",
    "under18Param": "&under_18=true",
    "dateFromParam": "&date_from=2024-07-01",
    "dateToParam": "&date_to=2025-06-30"
  },
  "novice": {
    "classLevelTitle": "Novice",
    "classLevelParam": "&height_levels[]=5",
    "under18Param": "&under_18=true",
    "dateFromParam": "&date_from=2024-07-01",
    "dateToParam": "&date_to=2025-06-30"
  },
}

const variables = {
  providerId: 10,
  currentYear: currentYear,
  years: years,
  allClassLevels: allClassLevels,
  allClasses: allClasses,
  majorClassLevels: majorClassLevels,
  majorClasses: allClasses.filter(majorClassesFilter),
  pointsLeagueDateFrom: "2022-05-12",
  powerAverageRegionTitles: powerAverageRegionTitles,
  powerAverageClassLevelParams: powerAverageClassLevelParams
};

export default variables;
