import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HStack, Spacer, Text, Dropdown } from "../components";
import { theme, envVars } from "../config";
import { useResource } from "../hooks";
import LeaguePrizeTable from "../components/LeaguePrizeTable";
import { navigate, useLocation } from "@reach/router"

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: 70%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const TableContainer = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid ${theme.colors.grey15};
`;

const ListItemContainer = styled.tr`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x2};
  background: ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
  &:hover {
    background: ${({ isHeader }) =>
    isHeader ? theme.colors.grey15 : theme.colors.secondary20};
  }
`;

const Column = styled.td`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  @media (${theme.breakpoints.mediumAndUp}) {
    display: block;
  }
`;

const Header = styled.th`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  font-size: ${theme.spacing.x4};
  color: ${theme.colors.secondary100};
  text-align: ${({ align }) => (align ? align : "left")};
  font-weight: ${theme.fontWeight.bold};
  @media (${theme.breakpoints.mediumAndUp}) {
    font-size: ${theme.spacing.x5};
    display: block;
  }
`;

const Container = styled.table`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.x4};
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const AdvertContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 29%;
  }
`;

const Advert = styled.img`
  display: flex;
  background: ${theme.colors.secondary50};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 2px solid ${theme.colors.grey15};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  max-width: 100%;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    align-items: top;
  }
`;

const ListItem = ({ item, idx }) => {
  return (
    <ListItemContainer idx={idx}>
      <Spacer height="x2" />
      <HStack align="center" justify="space-between">
        <Column width={"5%"}>
          <Text
            color="grey100"
            size="x3"
            weight="bold"
          >{`${item.attributes.rank}.`}</Text>
        </Column>
        <Column width={"15%"}>
          <img
            src={`https://data.fei.org/Content/Images/Flags/${item.attributes.nf}.png`}
            alt={`Flag: ${item.attributes.nf}`}
          />
        </Column>
        <Column width={"35%"}>
          <Text color="grey100" size="x4" mobileSize="x2" weight="semibold">
            {item.attributes.name}
          </Text>
          <Text color="grey80" size="x3" mobileSize="x1" weight="semibold">
            {item.attributes.with}
          </Text>
        </Column>
        <Column width={"10%"}>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.obpCount}
          </Text>
        </Column>
        <Column width={"15%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.obpOcSum}
          </Text>
        </Column>
        <Column width={"10%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.obpObSum}
          </Text>
        </Column>
        <Column width={"10%"}>
          <Text size="x4" weight="bold" align="right" color="grey100">
            {`${(parseFloat(item.attributes.obp) * 100).toFixed(1)}%`}
          </Text>
        </Column>
      </HStack>
      <Spacer height="x2" />
    </ListItemContainer>
  );
};

const BannerImage = styled.img`
  display: none;
  cursor: pointer;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 100%;
    display: flex;
    margin-bottom: ${theme.spacing.x6};
    background: ${theme.colors.secondary50};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 2px solid ${theme.colors.grey15};
    align-items: center;
    justify-content: center;
  }
`;

const OBPLeagues = ({ year, partner, reportName }, ...props) => {
  const [showMoreText, setShowMoreText] = useState(false);
  const [obpIndex, setObpIndex] = useState(10);

  const location = useLocation();
  const urlRoot = location.pathname.replace(`${year}`, "")

  let url = `gbr_obp_league` +
    `?report_name=${reportName}` +
    `&report_type=combination` +
    `&date_from=${year}-01-01` +
    `&return_top=100`;

  const { getAll: getAllObps, all: obps } = useResource(url);

  useEffect(() => {
    getAllObps();
  }, [getAllObps]);

  return obps ? (
    <>
      <HStack align="center" justify="space-between">
        <Text size="x6" weight="semibold">
          {partner} OBP League
        </Text>
      </HStack>
      <Spacer height="x6" />
      <Text size="x5" weight="normal">
        <b>Opposition Beaten Percentage (OBP)</b> - The search for British
        Eventing’s most consistent horse.
      </Text>
      {reportName === "Overall"
        ? null
        : reportName === "Newcomers"
          ? (
            <>
              <Spacer height="x2" />
              <Text size="x5" weight="normal">
                The Newcomers League qualifies for a share of the BE80, BE90 and
                BE100 <b>£10,000</b> prize money pot
              </Text>
            </>
          )
          : (
            <>
              <Spacer height="x2" />
              <Text size="x5" weight="normal">
                The {partner} League qualifies for a share of the <b>£10,000</b>{" "}
                prize money pot.
              </Text>
            </>
          )
      }
      <Spacer height="x4" />
      <HStack align="center" justify="space-between">
        <Text size="x4" weight="normal">
          {reportName === "Overall"
            ? "The Overall OBP league tracks every level of competition within British Eventing, with no restrictions based on who is included. The Opposition Beaten Percentage (OBP) is a number designed by EquiRatings to highlight the horses which are performing with the most consistency every season. The OBP is a single number that represents how many combinations you have competed against in a season, and what percentage of them you finished ahead of."
            : reportName === "Newcomers"
              ? "The Newcomer League tracks ‘new members’ results and the Opposition Beaten Percentage (OBP) is a number designed by EquiRatings to highlight the most consistent combinations competing each season. The OBP is a single number that represents how many combinations you have competed against in a season, and what percentage of them you finished ahead of."
              : "The Opposition Beaten Percentage (OBP) is a number designed by EquiRatings to highlight the horses which are performing with the most consistency every season. The OBP is a single number that represents how many combinations you have competed against in a season, and what percentage of them you finished ahead of."
          }
        </Text>
      </HStack>
      {showMoreText &&
        (reportName === "Newcomers" ? (
          <>
            <Spacer height="x2" />
            <Text size="x4" weight="normal">
              Wins and top placings catch the eye but we also know that
              finishing well in big fields is definitely something to be
              celebrated. OBP is sensitive to field size, so the more
              competitors you've faced (and finished ahead of), the higher your
              OBP.
            </Text>
            <Spacer height="x2" />
            <Text size="x4" weight="normal">
              If you only started competing with BE during the last two seasons,
              or if your membership had lapsed for a minimum of three years
              prior to rejoining then the Newcomers league has been created
              entirely for you. To be eligible for the Newcomers League you need
              to not only be classed as a new member but you also cannot have
              competed at Intermediate or higher or at CCI5* in the preceding
              five seasons
            </Text>
            <Spacer height="x2" />
            <Text size="x4" weight="normal">
              In this league, OBP only considers competitions where the
              combination starts the cross- country phase. Each eligible
              horse/rider combination will be part of the league and in the case
              of a tie, the combination with the most runs will win. If there is
              still a tie the horse with more opponents contested will win.
            </Text>
            <Spacer height="x2" />
            <Text size="x4" weight="normal">
              Where an event spans a month end, the qualification period will
              fall within the month the first day of competition fell
            </Text>
            <Spacer height="x2" />
            <Text size="x4" weight="normal">
              To learn more about the prizes, please read the{" "}
              <a href="/leagues_about">About</a> section
            </Text>
            <Spacer height="x2" />
            <LeaguePrizeTable />
          </>
        ) : (
          <>
            <Spacer height="x2" />
            <Text size="x4" weight="normal">
              Wins and top placings catch the eye but we also know that
              finishing well in big fields is definitely something to be
              celebrated. OBP is sensitive to field size, so the more
              competitors you've faced (and finished ahead of), the higher your
              OBP.
            </Text>
            {reportName !== "Overall" && (
              <>
                <Spacer height="x2" />
                <Text size="x4" weight="normal" color="primary50">
                  For the BE80, BE90, BE100 riders cannot have competed at Intermediate or higher in
                  the current or preceding 5 seasons, or at CCI5*.
                </Text>
                <Spacer height="x2" />
                <Text size="x4" weight="normal" color="primary50">
                  OBP only considers competitions where a horse starts the cross-country phase.
                  Each horse with three or more cross-country starts throughout the {year} British
                  Eventing season will be part of the league
                </Text>
                <Spacer height="x2" />
                <Text size="x4" weight="normal" color="primary50">
                  In the case of a tie, the horse with more runs will win. If
                  still tied, the horse with more opponents contested will win.
                </Text>
                <Spacer height="x2" />
                <Text size="x4" weight="normal" color="primary50">
                  For a result to count towards the league, the horse and rider must hold a current
                  British Eventing Introductory membership or higher at the time of the result
                </Text>
                <Spacer height="x2" />
                <Text size="x4" weight="normal">
                  To learn more about the prizes, please read the{" "}
                  <a href="/leagues_about">About</a> section
                </Text>
                <Spacer height="x2" />
                <LeaguePrizeTable />
              </>
            )}
          </>
        ))}
      <Spacer height="x2" />
      <Text
        onClick={() => setShowMoreText(!showMoreText)}
        size="x4"
        weight="bold"
        style={{ cursor: "pointer" }}
      >
        {showMoreText ? "Show less" : "Read more"}
      </Text>
      <Spacer height="x6" />
      <BannerImage
        src={require("../assets/beBanner.png")}
        width="100%"
        onClick={() =>
          window.open("https://www.britisheventing.com/join/join-2022")
        }
      />
      <OutterWrapper>
        <DropdownContainer>
          <Dropdown
            title="Year: "
            titlePosition="left"
            onChange={(item) => navigate(`${urlRoot}${item}`)}
            readOnly={true}
            items={[envVars.currentYear, envVars.currentYear - 1]}
            itemToString={(item) => item}
            initialItem={year}
            placeholder="Select a Year"
            handleFilter={() => true}
            displaySuggestion={(item) => <Text size="x3">{item}</Text>}
          />
        </DropdownContainer>
      </OutterWrapper>
      <Spacer height="x4" />
      <Wrapper>
        <OutterWrapper>
          <Container>
            <TableContainer>
              <ListItemContainer isHeader={true}>
                <Spacer height="x2" />
                <HStack align="center" justify="space-between">
                  <Header width="5%"> </Header>
                  <Header width="15%">Nation</Header>
                  <Header width="35%">
                    Athlete
                  </Header>
                  <Header width="10%" align="right">
                    Runs
                  </Header>
                  <Header width="15%" align="right" hideOnMobile>
                    Contested
                  </Header>
                  <Header width="10%" align="right" hideOnMobile>
                    Beaten
                  </Header>
                  <Header width="10%" align="right">
                    OBP
                  </Header>
                </HStack>
                <Spacer height="x2" />
              </ListItemContainer>
              {obps.data && obps.data.slice(0, obpIndex).map((item, idx) => {
                return <ListItem key={idx} item={item} idx={idx} />;
              })}
              {obps.data && obpIndex < obps.data.length && (
                <div
                  style={{
                    padding: theme.spacing.x2,
                    background: theme.colors.grey15,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setObpIndex(obps.data.length)}
                >
                  <Text
                    align="right"
                    size="x5"
                    weight="medium"
                    color="secondary100"
                  >
                    See More
                  </Text>
                </div>
              )}
            </TableContainer>
          </Container>
        </OutterWrapper>
        <Spacer height="x4" />
        <AdvertContainer>
          <Advert
            src={require("../assets/be4.png")}
            onClick={() =>
              window.open("https://qr.equiratings.com/heretohelp")
            }
          />
          <Spacer height="x4" />
          <Advert
            src={require("../assets/be5.png")}
            onClick={() =>
              window.open("https://qr.equiratings.com/bepodcast")
            }
          />
          <Spacer height="x4" />
          <Advert
            src={require("../assets/be3.png")}
            onClick={() =>
              window.open("https://www.eventingcheck.co.uk/index.php")
            }
          />
        </AdvertContainer>
      </Wrapper>
    </>
  ) : null;
};

export default OBPLeagues;
