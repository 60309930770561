import React from "react";
import styled from "styled-components";
import { HStack, Spacer, Text } from "../components";
import { theme } from "../config";

const TitleContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-wrap: no-wrap;
  }
`;

const RecordBoxContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 2px solid ${theme.colors.grey40};
  margin-bottom: ${theme.spacing.x4};
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 50%;
  }
`;

const RecordTitleContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x2} ${theme.spacing.x4};
  background: ${theme.colors.primary100};
`;

const RecordItemContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border-bottom: ${({ showBorder }) =>
    showBorder ? `2px solid ${theme.colors.grey40}` : "none"};
`;

const RecordName = styled.div`
  padding: ${theme.spacing.x3} ${theme.spacing.x4};
  width: 50%;
  border-right: ${({ showBorder }) =>
    showBorder ? ` 2px solid ${theme.colors.grey40}` : "none"};
`;

const RecordItem = ({ name, record, showBorder }) => {
  return (
    <RecordItemContainer showBorder={showBorder}>
      <RecordName showBorder>
        <Text size="x3" weight="semibold">
          {name}
        </Text>
      </RecordName>
      <RecordName>
        <Text size="x3">{record}</Text>
      </RecordName>
    </RecordItemContainer>
  );
};

const RecordBox = ({ title, items }) => {
  return (
    <RecordBoxContainer>
      <RecordTitleContainer>
        <Text color="white100" weight="semibold" size="x4">
          {title}
        </Text>
      </RecordTitleContainer>
      {items.map((item, idx) => {
        return (
          <RecordItem
            key={idx}
            name={item.name}
            record={item.record}
            showBorder={idx !== items.length - 1}
          />
        );
      })}
    </RecordBoxContainer>
  );
};

const Records = () => {
  const records = [
    {
      title: "Dressage Records",
      items: [
        {
          name: "Lowest Average DR Score in a season (Riders is 5 or more runs)",
          record: "Marcelo Tosi - 2017 - 21.4",
        },
        {
          name: "Most sub-28 DR Scores in a season",
          record: "Oliver Townend - 2022 - 94",
        },
      ],
    },
    {
      title: "Cross Country Records",
      items: [
        {
          name: "Most XCJ Clear Rounds in a season",
          record: "Oliver Townend - 2013 - 206",
        },
        {
          name: "Most XCT Clears in a season",
          record: "Oliver Townend - 2015 - 111",
        },
        {
          name: "Most XCJ Clear Rounds in a season (Advanced and above)",
          record: "Oliver Townend - 2016 - 48",
        },
        {
          name: "Most XCT Clears in a season (Advanced and above)",
          record: "Oliver Townend - 2016 - 17",
        },
        {
          name: "Highest number of XC runs in a season and still 100% clear (All Levels)",
          record: "Millie Dumas - 2018 - 46",
        },
        {
          name: "Highest number of XC runs in a season and still 100% clear (Advanced and above)",
          record: "Piggy French - 2019 - 23",
        },
        {
          name: "Longest XCJ Clear Streak Rounds in a season",
          record: "Katie Stephens-Grandy - 2016-2023 - 131",
        },
      ],
    },
    {
      title: "Showjumping Records",
      items: [
        {
          name: "Most SJ Clear Rounds in a season",
          record: "Oliver Townend - 2013 - 130",
        },
        {
          name: "Most SJ Clear Rounds in a season (Advanced and above)",
          record: "Andrew Nicholson - 2015 - 28",
        },
        {
          name: "Highest number of SJ runs in a season and still 100% clear (Advanced and above)",
          record: "Kitty King - 2021 - 15",
        },
      ],
    },
    {
      title: "Overall Records",
      items: [
        {
          name: "Most Wins in a Season",
          record: "Piggy French - 2019 - 36",
        },
        {
          name: "Most Wins in a Season (Advanced and above)",
          record: "Oliver Townend - 2016 - 9",
        },
        {
          name: "Most podiums in a season",
          record: "Oliver Townend - 2015 - 95",
        },
        {
          name: "Most podiums in a season (Advanced and above)",
          record: "Oliver Townend - 2016 - 22",
        },
        {
          name: "Most sub-28 finishings in a season (All-Levels)",
          record: "Oliver Townend - 2022 - 47",
        },
        {
          name: "Most sub-28 finishings in a season (Advanced & above)",
          record: "Kitty King (2021), Piggy March (2022), Oliver Townend (2022) - 5",
        },
      ],
    },
  ];
  return (
    <>
      <HStack align="center">
        <TitleContainer>
          <Text size="x6">Records</Text>
        </TitleContainer>
      </HStack>
      <Spacer height="x4" />
      {records.map((item, idx) => {
        return <RecordBox key={idx} title={item.title} items={item.items} />;
      })}
    </>
  );
};

export default Records;
