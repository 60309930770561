import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HStack, Spacer, Text } from "../components";
import { theme, envVars } from "../config";
import { useResource } from "../hooks";

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
    width: ${props => props.mediumAndUpWidth || "100%"};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  @media (${theme.breakpoints.mediumAndUp}) {
    flex-direction: row;
  }
`;

const TableContainer = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 2px solid ${theme.colors.grey15};
`;

const Table = styled.table`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  margin-top: ${theme.spacing.x4};
  flex-direction: column;
  @media (${theme.breakpoints.mediumAndUp}) {
    margin-top: 0;
  }
`;

const TableRow = styled.tr`
  box-sizing: border-box;
  width: 100%;
  padding: ${theme.spacing.x4} ${theme.spacing.x2};
  background: ${({ idx }) =>
    idx % 2 === 0 ? theme.colors.white100 : theme.colors.grey15};
  &:hover {
    background: ${({ isHeader }) =>
    isHeader ? theme.colors.grey15 : theme.colors.secondary20};
  }
`;

const Header = styled.th`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  font-size: ${theme.spacing.x4};
  color: ${theme.colors.secondary100};
  text-align: ${({ align }) => (align ? align : "left")};
  font-weight: ${theme.fontWeight.bold};
  @media (${theme.breakpoints.mediumAndUp}) {
    font-size: ${theme.spacing.x5};
    display: block;
  }
`;

const Column = styled.td`
  display: ${({ hideOnMobile }) => (hideOnMobile ? "none" : "block")};
  width: ${({ width }) => width};
  padding: 0 ${theme.spacing.x1};
  @media (${theme.breakpoints.mediumAndUp}) {
    display: block;
  }
`;

const AdvertContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: ${props => props.mediumAndUpWidth || "0%"};
  }
`;

const Advert = styled.img`
  display: flex;
  background: ${theme.colors.secondary50};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 2px solid ${theme.colors.grey15};
  align-items: center;
  justify-content: center;
  max-width: 100%;
  &:hover { cursor: pointer };
`;

const DataRow = ({ item, idx }) => {
  return (
    <TableRow idx={idx}>
      <HStack align="center" justify="space-between">
        <Column width={"5%"}>
          <Text
            color="grey100"
            size="x3"
            weight="bold"
          >{`${item.attributes.powerAverageRank}.`}</Text>
        </Column>
        <Column width={"15%"}>
          <img
            alt="Flag"
            src={`https://data.fei.org/Content/Images/Flags/${item.attributes.nf}.png`}
          />
        </Column>
        <Column width={"40%"}>
          <Text color="grey100" size="x4" mobileSize="x2" weight="semibold">
            {item.attributes.name}
          </Text>
          <Text color="grey80" size="x3" mobileSize="x1" weight="semibold">
            {item.attributes.results[0].with}
          </Text>
        </Column>
        <Column width={"10%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.results[0].score}
          </Text>
        </Column>
        <Column width={"10%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.results[1].score}
          </Text>
        </Column>
        <Column width={"10%"} hideOnMobile>
          <Text size="x4" weight="normal" align="right" color="grey100">
            {item.attributes.results[2].score}
          </Text>
        </Column>
        <Column width={"10%"}>
          <Text size="x4" weight="bold" align="right" color="grey100">
            {item.attributes.powerAverage}
          </Text>
        </Column>
      </HStack>
    </TableRow>
  );
};

const BannerImage = styled.img`
  display: none;
  &:hover { cursor: pointer };
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 100%;
    display: flex;
    margin-bottom: ${theme.spacing.x6};
    background: ${theme.colors.secondary50};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 2px solid ${theme.colors.grey15};
    align-items: center;
    justify-content: center;
  }
`;

const PowerAverageLeague = (
  {
    title,
    erLevels,
    heightLevels,
    horseAge,
    beGrassRoots,
    showRegion,
    groupBy,
    dateFrom,
    dateTo,
    excludePayg,
    text,
    bannerImageDetails,
    advertsDetils,
    minCountingRuns
  },
  ...props
) => {
  const [paIndex, setPaIndex] = useState(10);

  groupBy = groupBy || "horse"

  horseAge = horseAge || []
  let horseAgeParam = "";
  horseAge.forEach((i) => {
    horseAgeParam = horseAgeParam + `&ages[]=${i}`;
  });

  erLevels = erLevels || []
  let erLevelParam = "";
  erLevels.forEach((i) => {
    erLevelParam = erLevelParam + `&er_levels[]=${i}`;
  });

  heightLevels = heightLevels || []
  let heightLevelParam = "";
  heightLevels.forEach((i) => {
    heightLevelParam = heightLevelParam + `&height_levels[]=${i}`;
  });

  let minCountingRunsParam = (minCountingRuns && `&min_counting_runs=${minCountingRuns}`) || "";
  let beGrassRootsParam = (beGrassRoots && `&be_grass_roots=true`) || "";
  let showRegionParam = (showRegion && `&show_region=${showRegion}`) || "";

  dateFrom = dateFrom || `${envVars.currentYear}-01-01`;
  dateTo = dateTo || `${envVars.currentYear}-12-31`;

  let excludePaygParam = "&exclude_payg=true";
  if (excludePayg === false) {
    excludePaygParam = "";
  };

  let url = `provider/${envVars.providerId}/power_average` +
    `?group_by=${groupBy}` +
    `${excludePaygParam}` +
    `&return_top=100` +
    `${erLevelParam}` +
    `${heightLevelParam}` +
    `${horseAgeParam}` +
    `${minCountingRunsParam}` +
    `${beGrassRootsParam}` +
    `${showRegionParam}` +
    `&date_from=${dateFrom}` +
    `&date_to=${dateTo}`;

  const { getAll: getAllPowerAverages, all: powerAverages } = useResource(url);

  useEffect(() => {
    getAllPowerAverages();
  }, [getAllPowerAverages]);

  return powerAverages ? (
    <>
      <HStack align="center" justify="space-between">
        <Text size="x6" weight="semibold">
          {title}
        </Text>
      </HStack>
      <Spacer height="x6" />
      {text.map((item, idx) => {
        return (
          <React.Fragment key={idx}>
            <Spacer height="x2" />
            <Text
              size={!item.footnote ? "x5" : "x3"}
              weight="normal"
              color={!item.footnote ? "primary100" : "primary50"}
            >
              <b>{item.title}</b> {item.text}
            </Text>
            <Spacer height="x2" />
          </React.Fragment>
        );
      })}
      <Spacer height="x6" />
      {bannerImageDetails &&
        <BannerImage
          src={bannerImageDetails.imageSrc}
          width="100%"
          onClick={() =>
            window.open(bannerImageDetails.clickUrl)
          }
        />
      }
      <Wrapper>
        <OutterWrapper mediumAndUpWidth={advertsDetils && "70%"}>
          <Table>
            <TableContainer>
              <TableRow isHeader={true}>
                <HStack align="center" justify="space-between">
                  <Header width="5%"> </Header>
                  <Header width="15%">Nation</Header>
                  <Header width="40%">Horse</Header>
                  <Header width="10%" align="right" hideOnMobile>
                    First Result
                  </Header>
                  <Header width="10%" align="right" hideOnMobile>
                    Second Result
                  </Header>
                  <Header width="10%" align="right" hideOnMobile>
                    Third Result
                  </Header>
                  <Header width="10%" align="right">
                    Power Avg
                  </Header>
                </HStack>
              </TableRow>
              {powerAverages.data.slice(0, paIndex).map((item, idx) => {
                return <DataRow key={idx} item={item} idx={idx} />;
              })}
              {paIndex < powerAverages.data.length && (
                <div
                  style={{
                    padding: theme.spacing.x2,
                    background: theme.colors.grey15,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setPaIndex(powerAverages.data.length)}
                >
                  <Text
                    align="right"
                    size="x5"
                    weight="medium"
                    color="secondary100"
                  >
                    See More
                  </Text>
                </div>
              )}
            </TableContainer>
          </Table>
        </OutterWrapper>
        <Spacer height="x4" />
        {advertsDetils &&
          <AdvertContainer mediumAndUpWidth="29%">
            {advertsDetils.map((advert, idx) => {
              let spacer
              if (idx > 0) (spacer = <Spacer height="x4" />)
              let add = <Advert
                alt="Advert"
                src={advert.imageSrc}
                onClick={() =>
                  window.open(advert.clickUrl)
                }
              />

              return (
                <React.Fragment key={idx}>
                  {spacer}
                  {add}
                </React.Fragment>
              );
            })}
          </AdvertContainer>
        }
      </Wrapper>
    </>
  ) : null;
};

export default PowerAverageLeague;
