import React from "react";
import styled from "styled-components";
import HStack from "./HStack";
import { theme } from "../config";

const HeaderContainer = styled.header`
  background: ${theme.colors.white100};
  border-bottom: 0.5px solid ${theme.colors.grey30};
  padding: ${theme.spacing.x6} ${theme.spacing.x4};
`;

const HamburgerContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-right: 10%;
  @media (${theme.breakpoints.mediumAndUp}) {
    display: none;
  }
`;

const Image = styled.img`
  height: ${({ height }) => height || "30px"};
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0px;
  }
  @media (${theme.breakpoints.mediumAndUp}) {
    height: ${({ height }) => height || "30px"};
    margin-bottom: 0px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  @media (${theme.breakpoints.mediumAndUp}) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`;

const Header = ({ setShowSidebar, showSidebar, showBurger = true }) => {
  return (
    <HeaderContainer>
      <HStack align="center" justify="space-between">
        <HStack align="center">
          {
            showBurger
              ? <HamburgerContainer onClick={() => setShowSidebar(!showSidebar)}>
                <svg
                  fill={theme.colors.primary100}
                  width="30"
                  height="30"
                  viewBox="0 0 100 70"
                >
                  <path d="M0 0h100v10H0zM0 30h100v10H0zM0 60h100v10H0z" />
                </svg>
              </HamburgerContainer>
              : null
          }
          <ImageContainer>
            <Image height="30px" src={require("../assets/BE_Logo.png")} />
            <Image height="20px" src={require("../assets/poweredByER.png")} />
          </ImageContainer>
        </HStack>
      </HStack>
    </HeaderContainer>
  );
};

export default Header;
