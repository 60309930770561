import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import { Header, Sidebar, Layout, Text, Spacer } from "./components";
import { Location } from "@reach/router";
import { envVars } from "./config";
import { useResource } from "./hooks";
import styled from "styled-components";

const OutterWrapper = styled.div`
  width: 100vw;
  display: flex;
  align-items: top;
  justify-content: center;
  align-items: center;
`;

const InnerContent = styled.div`
  margin-horizontal: 32px;
  max-width: calc(100% - 64px);
`;

function getCookie(name) {
  const cookies = document.cookie.split("; ");
  for (let cookie of cookies) {
    const [key, value] = cookie.split("=");
    if (key === name) return value;
  }
  return null;
}

function setSessionCookie(token, expiryTime) {
  document.cookie = `sessionToken=${token}; path=/; expires=${new Date(expiryTime).toUTCString()}; Secure;`;
}

function App() {
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeRoute, setActiveRoute] = useState(0);

  var mediaQuery = window.matchMedia("(min-width: 60em)");

  useEffect(() => {
    function handleSidebarVisibility(mediaQuery) {
      if (mediaQuery.matches) {
        setShowSidebar(false);
      }
    }

    handleSidebarVisibility(mediaQuery); // Call listener function at run time
    mediaQuery.addEventListener("change", handleSidebarVisibility);

    return () => {
      mediaQuery.removeEventListener("change", handleSidebarVisibility);
    };

  }, [mediaQuery]);



  const params = new URLSearchParams(window.location.search);

  const [token, setToken] = useState(params.get("token"));
  const [isVerifyingToken, setIsVerifyingToken] = useState(false);
  const [errorVerifyingToken, setErrorVerifyingToken] = useState(false);

  const url = `provider/${envVars.providerId}/verify_jwt?token=${token}`;
  const { getAll: getTokenVerification, all: tokenVerification, error: tokenError } = useResource(url);

  useEffect(
    () => {
      const verifyToken = async () => {
        setIsVerifyingToken(true);

        await getTokenVerification();

        const params = new URLSearchParams(window.location.search);
        params.delete("token");
        const newUrl = `${window.location.pathname}${params.toString() ? `?${params.toString()}` : ""}`;
        window.history.replaceState({}, document.title, newUrl);

        setToken(null);

        setIsVerifyingToken(false);
      };

      if (token) {
        verifyToken();
      };
    },
    [token, getTokenVerification]
  );

  const [isSettingSession, setIsSettingSession] = useState(false);

  useEffect(
    () => {
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

      if (tokenVerification) {
        setIsSettingSession(true);

        const { tokenIsValid, exp, token } = tokenVerification.data?.attributes;

        if (tokenIsValid) {
          const expiryTime = (exp && exp * 1000) || oneYearAgo;
          setSessionCookie(token, expiryTime);
        } else {
          setSessionCookie(token, oneYearAgo);
        };

        setIsSettingSession(false);
      };

      if (tokenError) {
        setSessionCookie("error", oneYearAgo);
        setErrorVerifyingToken(true);
      };
    },
    [tokenVerification, tokenError]
  );

  if (isVerifyingToken || isSettingSession) {
    return <>
      <Header showBurger={false} />
      <Spacer height="x10" />
      <OutterWrapper>
        <InnerContent >
          <Text size="x6" weight="bold">Authentitacing...</Text>
        </InnerContent>
      </OutterWrapper>
    </>
  };

  if (errorVerifyingToken) {
    return <>
      <Header showBurger={false} />
      <Spacer height="x10" />
      <OutterWrapper>
        <InnerContent >
          <Text size="x6" weight="bold">There was an error authenticating.</Text>
          <Text size="x4" weight="bold">Please try again later.</Text>
        </InnerContent>
      </OutterWrapper>
    </>
  };

  if (getCookie("sessionToken")) {
    return (
      <Location>
        {({ location }) => {
          return (
            <>
              <Header
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
                activeRoute={activeRoute}
                setActiveRoute={setActiveRoute}
              />
              <div style={{ display: "flex" }}>
                <Sidebar
                  setShowSidebar={setShowSidebar}
                  showSidebar={showSidebar}
                  activeRoute={activeRoute}
                  setActiveRoute={setActiveRoute}
                />
                <Layout>
                  <Navigation />
                </Layout>
              </div>
            </>
          );
        }
        }
      </Location>
    );
  } else {
    return <>
      <Header showBurger={false} />
      <Spacer height="x10" />
      <OutterWrapper>
        <InnerContent >
          <Text size="x6" weight="bold">
            Sorry your access to this site has been restricted
          </Text>
          <Spacer height="x8" />
          <Text size="x4" weight="normal">
            This page is only available to active British Eventing members.
          </Text>
          <Spacer height="x4" />
          <Text size="x4" weight="normal">
            To verify your membership and gain access, please follow the steps below:
          </Text>
          <Spacer height="x2" />
          <ul>
            <li>
              <Text size="x4" weight="normal">
                Log in to your BE account on the official BE website.
                This can be found <a href="https://www.britisheventing.com/user">here</a> .
              </Text>
            </li>
            <Spacer height="x4" />
            <li>
              <Text size="x4" weight="normal">
                Navigate to the “My Account“ section.
              </Text>
            </li>
            <Spacer height="x4" />
            <li>
              <Text size="x4" weight="normal">
                If you hold an active membership, you will see a button labeled “BE Stats Centre”.
              </Text>
            </li>
          </ul>
          <Spacer height="x8" />
          <Text size="x4" weight="normal">
            If you believe this is an error or require further assistance, please contact BE support.
          </Text>
        </InnerContent>
      </OutterWrapper>
    </>
  }
}

export default App;
